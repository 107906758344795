body {
    font-family: 'Lora', serif;
    cursor: default;
    margin: 0;
}

* {
    text-align: start;
    -webkit-tap-highlight-color: transparent !important;
}

textarea {    
    resize: none;
}

textarea:focus {
    outline: none;
}