.whiteboard-container {
    position: relative;
    width: 100%;
    height: 300px;
    background-color: #fff;
}

.mv {
    display: none;
}

.table-center td, .table-center th {
    text-align: center;
    border: 1px solid #12529f;
}

.class_policy_list li {
    padding: 8px 4px;
    text-align: justify;
}

.navigation {
    width: 5%;
    position: fixed;
    left: 0px;
    padding: .5%;
    height: 99%;
    overflow: hidden;
    border-radius: 5px;
    margin: .25% .5%;
}

.nav_btn {
    width: 100%;
    padding: 0px !important;
    height: 40px;
    margin: 12px 0px;
    display: block;
    border-radius: 5px !important;
}

.nav_btn_ico {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 5px;
}

.view {
    width: 93%;
    margin: .25% .5%;
    position: fixed;
    left: 5.75%;
    height: 99%;
    border-radius: 5px;
    background-color: inherit !important;
}

.class_ {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
}

.class_ > div:first-child {
    width: 30%;
    margin: 0px .5%;
    margin-left: 0px;
    height: 100%;
}

.class_ > div:last-child {
    width: 68.5%;
    margin: 0px .5%;
    height: 100%;
}

.class_room_t {
    position: relative;
    height: 100%;
}

.class_room_header {
    position: relative;
    top: 0px;
    width: 100%;
}

.class_room_body {
    height: 88.5%;
}

.class_room_footer {
    position: absolute;
    bottom: 5px;
    z-index: 99999;
    width: 100%;
}

.class_room_footer > .create_holder {
    width: 15%;
    position: relative;
}

.class_room_footer > .text_holder {
    width: 68%;
    margin: 0px 1% ;
}
.class_room_footer > .send_holder {
    width: 15%;
    position: relative;
}

.class_room_footer_icons {
    font-size: 25px;
    height: 30px;
}

.txA {
    height: 80px;
}

.emoji_holder {
    max-width: 400px !important;
    position: fixed;
    bottom: 95px;
}

.class_create_tools {
    width: 230px;
    height: fit-content;
    border-radius: 6px;
    padding: 8px;
    position: fixed;
    bottom: 95px;
}

.tool_con {
    position: fixed;
    right: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999998;
    background-color: rgba(0,0,0,.3);
}

.tool_con .tool_holder .canc_btn {
    position: fixed;
    right: 12px;
    top: 12px;
}

.tool_con .tool_holder {
    position: absolute;
    width: 600px;
    height: 550px;
    margin: auto;
    padding: 8px 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.tool_con .tool_holder .tool_holder_header {
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 12px;
}

.tool_con .tool_holder .tool_holder_body {
    position: relative;
    top: 0px;
    left: 0px;
    margin-top: 50px;
    height: 440px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px 6px;
}

.tool_con .tool_holder .tool_holder_footer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding: 12px;
    width: 100%;
}

.tool_con .tool_holder .tool_holder_footer button {
    position: fixed;
    bottom: 12px;
    right: 12px;
}

.wrapper-class {
    padding: 10px;
    border: none !important;
}

.text_holder .wrapper-class {
    padding: 2px !important;
    height: fit-content !important;
}

.text_holder .editor-class {
    background-color: #fff;
    padding: 2px 8px !important;
    margin: 0px !important;
    border: 1px solid #ccc;
    height: 80px !important;
}

.text_holder .toolbar-class {
    position: absolute;
    bottom: 90px;
    left: 50%;
    transform: translate(-50%);
    width: 250px;
    padding: 3px 0px;
}

.text_holder .rdw-emoji-modal {
    position: absolute;
    top: -220px;
    left: 50%;
    
}

.viewtexteditor >p {
    margin: 0px;
    padding: 0px;
}

.editor-class {
    background-color: #fff;
    padding: 1rem;
    border: 1px solid #ccc;
}

.toolbar-class {
    border: 1px solid #ccc;
}

input[type=range] {
    width: 100%;
    padding: 0 1%;
    background-color: transparent;
    cursor: pointer;
    appearance: none;
    transition: all .3s;
    border-radius: 13px;
    --track-color: #12529f;
}

input[type=range]::-webkit-slider-thumb {
    appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 1px;
    background-color: #12529f;
    transition: all ease .3s;
    box-shadow: -10000px 0px 0px 9995px #12529f;

}

input[type=range]::-moz-range-progress {
    background-color: #12529f;
}

input[type=range]::-ms-fill-lower {
    background-color: #12529f;
}



input[type=range]::-webkit-slider-runnable-track {
    background-color: #ccc;
    border-radius: 10px;
    height: 6px;
    position: relative;
    overflow: hidden;
    transition: all ease 300ms;
}

.audio_arr {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.audio_arr > * {
    width: 33%;
    font-size: 10px;
    margin: auto;
    height: 50px;
    padding: 0px;
}

.system_msg {
    text-align: center; 
    font-size: 10px !important; 
    font-style: italic; 
    width: fit-content; 
    display: block; 
    margin: 12px auto;
}

.profile_holder {
    position: relative;
    display: flex;
    overflow-y: scroll;
    height: 100%;    
}

.main_details_holder {
    margin: 0px auto;
    background-color: #f2f2f2;
    width: 40%;
    text-align: center;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 8px;
    height: fit-content;
}

.my_pp {
    width: 180px;
    height: 180px;
    margin: 20px auto;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 150px;
}

.my_pp>* {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.other_details_holder {
    margin: 0px auto;
    width: 55%;
}

.other_details_holder > div {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    margin: 12px auto;
}

.other_details_holder > div:first-child {
    margin: 0px auto;
}

@media only screen and (max-width: 699.9px) {
    .navigation {
        width: 100%;
        position: fixed;
        left: 0px;
        bottom: 0px !important;
        padding: 0% 3%;
        height: 7.5%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
        grid-gap: 5px;
        z-index: 998;
        border-radius: 0px;
        margin: 0px;
    }
    
    .nav_btn {
        width: 100%;
        padding: 0px !important;
        height: 37px;
        margin: 0px 0px;
        border-radius: 5px !important;
        background-color: inherit !important;
        box-shadow: none !important;
        text-align: center;
    }
    
    .nav_btn_ico {
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 6px;
        color: #12529f;
    }

    .nav_title {
        position: fixed;
        z-index: 998;
        padding: 0% 3%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
        grid-gap: 10px;
        text-align: center !important;
        bottom: 0px;
        width: 100%;
    }

    .nav_title > span {
        border-radius: 12px;
        font-size: 12px;
        font-weight: bold;
    }

    
    .view {
        width: 100%;
        margin: 0px;
        position: fixed;
        left: 0px;
        top: 0%;
        height: 92.5%;
        z-index: 998;
        background-color: inherit !important;
        border-radius: 5px;
    }

    .class_ {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        flex-wrap: wrap;
    }
    
    .class_ > div:first-child {
        width: 100%;
        margin: 0px 0%;
        margin-left: 0px;
        height: 92.5%;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 98;
    }
    
    .class_ > div:last-child {
        width: 100%;
        margin: 0px 0%;
        position: fixed;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 999999;
        background-color: #fff;
    }

    .class_room_header {
        position: relative;
        top: 0px;
        width: 100%;
    }
    
    
    .class_room_footer {
        position: absolute;
        bottom: 5px;
        z-index: 99999;
        width: 100%;
    }

    .class_room_footer > .create_holder {
        width: 17%;
        position: relative;
        overflow: hidden;
    }

    .class_room_footer > .text_holder {
        width: 63%;
        margin: 0px 1% ;
    }
    .class_room_footer > .send_holder {
        width: 17%;
        position: relative;
        overflow: hidden;
    }

    .emoji_holder {
        max-width: 100% !important;
        position: fixed;
        left: 0px;
        bottom: 90px;
        z-index: 999;
    }

    .class_create_tools {
        border-radius: 6px;
        padding: 8px;
        position: fixed;
        bottom: 90px;
    }

    .tool_con {
        position: fixed;
        right: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 999998;
        background-color: rgba(0,0,0,.3);
        overflow: hidden;
    }
    
    .tool_con .tool_holder .canc_btn {
        position: fixed;
        right: 8px;
        top: 8px;
    }
    
    .tool_con .tool_holder {
        position: fixed;
        width: 100%;
        height: 100%;
        margin: auto;
        padding: 8px 12px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        overflow-y: scroll;
        overflow-x: hidden;
    }
    
    .tool_con .tool_holder .tool_holder_header {
        position: fixed;
        top: 0px;
        left: 0px;
        padding: 14px;
        width: 100%;
        height: fit-content;
        overflow: hidden;
    }
    
    .tool_con .tool_holder .tool_holder_body {
        position: static;
        top: 0px;
        left: 0px;
        width: 100%;
        height: fit-content;
        margin-top: 50px;
        overflow: hidden;
    }
    
    .tool_con .tool_holder .tool_holder_footer {
        position: relative;
        padding: 0px;
        width: 100%;
        height: 10%;
        bottom: 0px;
        margin-top: 12px;
        left: 0px;
    }
    
    .tool_con .tool_holder .tool_holder_footer button {
        position: relative;
        bottom: 0px;
        right: 0px;
        width: 100%;
        text-align: center;
    }

    .profile_holder {
        position: relative;
        display: block;
        overflow-y: scroll;
        height: 100%;
    }
    
    .main_details_holder {
        margin: 0px auto;
        margin-top: 0px;
        background-color: #f2f2f2;
        width: auto;
        max-width: 380px;
        text-align: center;
        padding: 10px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 8px;
    }
    
    .my_pp {
        width: 180px;
        height: 180px;
        margin: 20px auto;
        margin-top: 20px;
        overflow: hidden;
        border-radius: 150px;
    }
    
    .my_pp>* {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .other_details_holder {
        margin: 12px auto;
        width: auto;
        max-width: 380px;
        
    }
    
    .other_details_holder > div {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        background-color: #f2f2f2;
        padding: 10px;
        border-radius: 8px;
        text-align: center;
        margin: 12px auto;
    }
    
    .other_details_holder > div:first-child {
        margin: 0px auto;
    }
    
}

@media only screen and (max-height: 1199.9px) {
    .class_room_body {
        height: 86.5%;
    }
}

@media only screen and (max-height: 1099.9px) {
    .class_room_body {
        height: 85.5%;
    }
}

@media only screen and (max-height: 999.9px) {
    .class_room_body {
        height: 83%;
    }
}

@media only screen and (max-height: 899.9px) {
    .class_room_body {
        height: 81.5%;
    }
}

@media only screen and (max-height: 799.9px) {
    .class_room_body {
        height: 78.5%;
    }
}

@media only screen and (max-height: 699.9px) {
    .class_room_body {
        height: 75.5%;
    }
}

@media only screen and (max-height: 599.9px) {
    .class_room_body {
        height: 71.5%;
    }
}